export const DashboardType = {
  GET_USER_COUNT_REQUEST: "GET_USER_COUNT_REQUEST",
  GET_USER_COUNT_SUCCESS: "GET_USER_COUNT_SUCCESS",
  GET_USER_COUNT_FAIL: "GET_USER_COUNT_FAIL",
  GET_WINNING_RATIO_COUNT_REQUEST: "GET_WINNING_RATIO_COUNT_REQUEST",
  GET_WINNING_RATIO_COUNT_SUCCESS: "GET_WINNING_RATIO_COUNT_SUCCESS",
  GET_WINNING_RATIO_COUNT_FAIL: "GET_WINNING_RATIO_COUNT_FAIL",
  GET_MONEY_IN_OUT_COUNT_REQUEST: "GET_MONEY_IN_OUT_COUNT_REQUEST",
  GET_MONEY_IN_OUT_COUNT_SUCCESS: "GET_MONEY_IN_OUT_COUNT_SUCCESS",
  GET_MONEY_IN_OUT_COUNT_FAIL: "GET_MONEY_IN_OUT_COUNT_FAIL",
  GET_CUSTOMER_COUNT_REQUEST: "GET_CUSTOMER_COUNT_REQUEST",
  GET_CUSTOMER_COUNT_SUCCESS: "GET_CUSTOMER_COUNT_SUCCESS",
  GET_CUSTOMER_COUNT_FAIL: "GET_CUSTOMER_COUNT_FAIL",
  GET_COMMISION_COUNT_REQUEST: "GET_COMMISION_COUNT_REQUEST",
  GET_COMMISION_COUNT_SUCCESS: "GET_COMMISION_COUNT_SUCCESS",
  GET_COMMISION_COUNT_FAIL: "GET_COMMISION_COUNT_FAIL",
  GET_PAYMENT_COUNT_REQUEST: "GET_PAYMENT_COUNT_REQUEST",
  GET_PAYMENT_COUNT_SUCCESS: "GET_PAYMENT_COUNT_SUCCESS",
  GET_PAYMENT_COUNT_FAIL: "GET_PAYMENT_COUNT_FAIL",
  GET_DASHBOARD_CHART_REQUEST: "GET_DASHBOARD_CHART_REQUEST",
  GET_DASHBOARD_CHART_SUCCESS: "GET_DASHBOARD_CHART_SUCCESS",
  GET_DASHBOARD_CHART_FAIL: "GET_DASHBOARD_CHART_FAIL",
  GET_DASHBOARD_PROFITLIST_REQUEST: "GET_DASHBOARD_PROFITLIST_REQUEST",
  GET_DASHBOARD_PROFITLIST_SUCCESS: "GET_DASHBOARD_PROFITLIST_SUCCESS",
  GET_DASHBOARD_PROFITLIST_FAIL: "GET_DASHBOARD_PROFITLIST_FAIL",
  GET_DASHBOARD_CREDIT_LIST_REQUEST: "GET_DASHBOARD_CREDIT_LIST_REQUEST",
  GET_DASHBOARD_CREDIT_LIST_SUCCESS: "GET_DASHBOARD_CREDIT_LIST_SUCCESS",
  GET_DASHBOARD_CREDIT_LIST_FAIL: "GET_DASHBOARD_CREDIT_LIST_FAIL"
};
