import { LocationType } from "./type";

// Organizers intial state
const initialAuthState = {
  locations: null,
  location: null,
  isLoading: false,
  statusCode: null,
  locationDropdownList: [],
  locationDashboard: null,
  isDashboardLoading: false,
  isLogoutKioskLoading: false,
  isCashManagementLoading: false,
  cashManagementData: null,
  TransactionData: null,
  isTransactionLoading: false,
  isCycleItemReportLoading: false,
  cycleItemReportData: null,
  isCycleItemReportAllLoading: false,
  cycleItemReportAllData: null,
  dropDownList: null,
  isDropDownLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case LocationType.GET_LOCATIONS_REQUEST:
      return { ...state, isLoading: true };
    case LocationType.GET_LOCATIONS_SUCCESS:
      return { ...state, isLoading: false, locations: action.payload.data };
    case LocationType.GET_LOCATIONS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case LocationType.GET_SINGLE_LOCATION_REQUEST:
      return { ...state, isLoading: true, statusCode: null };
    case LocationType.GET_SINGLE_LOCATION_SUCCESS:
      return { ...state, isLoading: false, location: action.payload.data };
    case LocationType.GET_SINGLE_LOCATION_FAIL:
      return { ...state, isLoading: false, error: action.error, statusCode: action.statusCode };
    case LocationType.GET_LOCATION_DROPDOWN_SUCCESS:
      return { ...state, isLoading: false, locationDropdownList: action.payload.data };
    case LocationType.GET_LOCATION_DASHBOARD_REQUEST:
      return { ...state, isDashboardLoading: true };
    case LocationType.GET_LOCATION_DASHBOARD_SUCCESS:
      return { ...state, isDashboardLoading: false, locationDashboard: action.payload.data };
    case LocationType.GET_LOCATION_DASHBOARD_FAIL:
      return { ...state, isDashboardLoading: false, statusCode: action.statusCode };
    case LocationType.LOGOUT_KIOSK_REQUEST:
      return { ...state, isLogoutKioskLoading: true };
    case LocationType.LOGOUT_KIOSK_SUCCESS:
      return { ...state, isLogoutKioskLoading: false, location: { ...state.location, loginType: "" } };
    case LocationType.LOGOUT_KIOSK_FAIL:
      return { ...state, isLogoutKioskLoading: false };
    case LocationType.CASH_MANAGEMENT_REQUEST:
      return { ...state, isCashManagementLoading: true };
    case LocationType.CASH_MANAGEMENT_SUCCESS:
      return { ...state, isCashManagementLoading: false, cashManagementData: action.payload.data };
    case LocationType.CASH_MANAGEMENT_FAIL:
      return { ...state, isCashManagementLoading: false, cashManagementData: [] };
    case LocationType.TRANSACTION_REPORT_REQUEST:
      return { ...state, isTransactionLoading: true };
    case LocationType.TRANSACTION_REPORT_SUCCESS:
      return { ...state, isTransactionLoading: false, TransactionData: action.payload.data };
    case LocationType.TRANSACTION_REPORT_FAIL:
      return { ...state, isTransactionLoading: false, TransactionData: [] };
    case LocationType.CYCLE_ITEM_REPORT_REQUEST:
      return { ...state, isCycleItemReportLoading: true };
    case LocationType.CYCLE_ITEM_REPORT_SUCCESS:
      return { ...state, isCycleItemReportLoading: false, cycleItemReportData: action.payload };
    case LocationType.CYCLE_ITEM_REPORT_FAIL:
      return { ...state, isCycleItemReportLoading: false, cycleItemReportData: [] };
    case LocationType.CYCLE_ITEM_REPORT_ALL_REQUEST:
      return { ...state, isCycleItemReportAllLoading: true };
    case LocationType.CYCLE_ITEM_REPORT_ALL_SUCCESS:
      return { ...state, isCycleItemReportAllLoading: false, cycleItemReportAllData: action.payload.data };
    case LocationType.CYCLE_ITEM_REPORT_ALL_FAIL:
      return { ...state, isCycleItemReportAllLoading: false };
    case LocationType.DROPDOWN_LIST_REQUEST:
      return { ...state, isDropDownLoading: true };
    case LocationType.DROPDOWN_LIST_SUCCESS:
      return { ...state, isDropDownLoading: false, dropDownList: action.payload.data };
    case LocationType.DROPDOWN_LIST_FAIL:
      return { ...state, isDropDownLoading: false };
    default:
      return state;
  }
};
