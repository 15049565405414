import { Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SVG1 from "../../media/images/logo.png";
import {
  AdvertiseNew,
  CloseIcon,
  CustomerIcon,
  DashboardIcon,
  DistributorNew,
  DropdownIcone,
  DropdownUpIcone,
  InvoiceIcone,
  KioskNew,
  LocationIcon,
  MenuIcon,
  OperatorNew,
  PaypalIcone,
  SettingIcone,
  SubmenuIcone,
  TransactionNew,
  UserNew
} from "../../components/Icons";
import UserAccess from "../../utils/UserAccess";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function DrawerContents({ toggled, onClick }) {
  const location = useLocation();
  const { palette } = useTheme();
  const { user } = useSelector((state) => state.auth);

  function MenuItem({ title, to, icon, isActive = [], subMenu, children }) {
    const [open, setOpen] = useState(isActive?.some((x) => x === location.pathname.split("/")[1]));
    // const [open, setOpen] = useState(false);
    return (
      <>
        <ListItemButton
          key={title}
          title={title}
          selected={isActive?.findIndex((x) => x === location.pathname.split("/")[1]) > -1}
          disableRipple
          onClick={subMenu ? () => setOpen(!open) : () => (window.innerWidth > 900 ? null : onClick())}
          component={subMenu ? "button" : Link}
          to={to}
          sx={{
            padding: "8px 23px !important",
            width: "100%",
            background:
              isActive?.findIndex((x) => x === location.pathname.split("/")[1]) > -1
                ? `${palette.mode === "light" ? palette.secondary.main : palette.background.default} !important`
                : "transparent"
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: "40px",
                color:
                  isActive?.findIndex((x) => x === location.pathname.split("/")[1]) > -1
                    ? `${palette.mode === "light" ? palette.dark.main : palette.primary.main} !important`
                    : palette.grey.main
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={title}
            className="ellipsis"
            sx={{
              fontSize: "16px",
              color:
                isActive?.findIndex((x) => x === location.pathname.split("/")[1]) > -1
                  ? `${palette.primary.main} !important`
                  : ""
            }}
          />
          {subMenu && <> {open ? <DropdownIcone /> : <DropdownUpIcone />}</>}
        </ListItemButton>
        {subMenu && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" className="submenu">
              {children}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: toggled ? "20px !important" : "15px !important"
        }}
      >
        {toggled && <img src={SVG1} alt="" width={180} />}
        <IconButton onClick={onClick}>{window.innerWidth > 900 && toggled ? <MenuIcon /> : <CloseIcon />}</IconButton>
      </Toolbar>
      <List component="div">
        <MenuItem title="Dashboard" to="/dashboard" isActive={[``]} icon={<DashboardIcon />} />
        {UserAccess("user_list") && <MenuItem title="Users" to="/users" isActive={[`users`]} icon={<UserNew />} />}
        {UserAccess("invoice_list") && user?.userType !== "operator" && user?.userType !== "operator_user" && (
          <MenuItem
            title="Invoices"
            icon={<InvoiceIcone />}
            isActive={[`invoices-list`, `invoices-overview`, "invoices"]}
            subMenu={true}
            children={
              <>
                <MenuItem
                  title="Invoices List"
                  to="/invoices-list"
                  isActive={[`invoices-list`]}
                  icon={<SubmenuIcone />}
                />
                <MenuItem
                  title="Invoices Overview"
                  to="/invoices-overview"
                  isActive={[`invoices-overview`]}
                  icon={<SubmenuIcone />}
                />
              </>
            }
          />
        )}
        {UserAccess("view_bill") && user?.userType !== "superAdmin" && user?.userType !== "superUser" && (
          <MenuItem
            title="Payable"
            icon={<PaypalIcone />}
            isActive={[`payable-list`, `payable-overview`, `payable-statements`]}
            subMenu={true}
            children={
              <>
                <MenuItem title="Payable List" to="/payable-list" isActive={["payable-list"]} icon={<SubmenuIcone />} />
                <MenuItem
                  title="Payable Overview"
                  to="/payable-overview"
                  isActive={["payable-overview"]}
                  icon={<SubmenuIcone />}
                />
                {/* <MenuItem
                  title="Payable Statements"
                  to="/payable-statements"
                  isActive={["payable-statements"]}
                  icon={<SubmenuIcone />}
                /> */}
              </>
            }
          />
        )}
        {UserAccess("distributor_list") && (
          <MenuItem title="Distributors" to="/distributors" isActive={[`distributors`]} icon={<DistributorNew />} />
        )}
        {UserAccess("operator_list") && (
          <MenuItem title="Operators" to="/operators" isActive={[`operators`]} icon={<OperatorNew />} />
        )}
        {UserAccess("location_list") && (
          <MenuItem title="Locations" to="/locations" isActive={[`locations`]} icon={<LocationIcon />} />
        )}
        {UserAccess("customer_list") && (
          <MenuItem title="Players" to="/customers" isActive={[`customers`]} icon={<CustomerIcon />} />
        )}
        {UserAccess("transaction_list") && (
          <MenuItem title="Transactions" to="/transactions" isActive={[`transactions`]} icon={<TransactionNew />} />
        )}
        {UserAccess("advertisement_list") && (
          <MenuItem title="Advertises" to="/advertises" isActive={[`advertises`]} icon={<AdvertiseNew />} />
        )}
        {UserAccess("kiosk_model_list_tId") && (
          <MenuItem title="Terminals ID" to="/kiosks" isActive={[`kiosks`]} icon={<KioskNew />} />
        )}
        {/* {UserAccess("statement_list") && (
          <MenuItem title="Statements" to="/statements" isActive={["statements"]} icon={<StatementsIcone />} />
        )} */}
        {user?.userType === "superAdmin" && (
          <MenuItem title="Settings" to="/settings" isActive={["settings"]} icon={<SettingIcone />} />
        )}
      </List>
    </>
  );
}
